import { getChatInfo } from '@/api/chat/chat';
import { initStore } from '@/lib/store';
import type { StoreWithSelectors } from '@/lib/store';
import { ChatInfo, ChatMap, ChatType, ResMessage, UnlockMedia } from '@/types/chat';

import { useUserStore } from './user';

interface baseState {
    openChat: boolean;
    page: number;
    isEnd: boolean;
    switchChat: (input?: boolean) => void;
    currentChat: ChatType;
    setCurrentChat: (currentChat: Partial<ChatType>) => void;
    chatMap: ChatMap;
    setChatMapInfo: (chatInfo: ChatInfo) => void;
    setChatMapMessageList: (chatMessageList: ResMessage[], isInit: boolean) => void;
    setResMessage: (message: ResMessage) => void;
    chatList: ChatInfo[];
    setChatList: (chatList: ChatInfo[]) => void;
    setNewChatList: (chatInfo: ChatInfo) => void;
    setChatMapRead: (sessionId: string) => void;
    setChatMapMessageUnlock: (sessionId: string, info: UnlockMedia) => void;
    setPage: () => void;
    setIsEnd: (end: boolean) => void;
    resetChatList: () => void;
    chatUserUnread: number;
    chatCpUnread: number;
    setChatUserUnread: (num: number) => void;
    setChatUserUnreadInit: (num: number) => void;
    setChatCpUnread: (num: number) => void;
    setChatCpUnreadInit: (num: number) => void;
    updateReadTime: ({
        read_at,
        session_id,
        user_id
    }: {
        read_at: string;
        session_id: string;
        user_id: string;
    }) => void;
    replyData: {
        enable: boolean;
        img: string;
        description: string;
        video_id?: string;
        image_id?: string;
        drama_id?: string;
        episode_id?: string;
        trailer_id?: string;
    };
    setReplyData: ({
        img,
        description,
        type,
        id
    }: {
        img: string;
        description: string;
        type: string;
        id: string;
    }) => void;
    resetReplyData: () => void;
    isOpenSticker: boolean;
    setIsOpenSticker: (status: boolean) => void;
}

let useChatStore: StoreWithSelectors<baseState>;

const initialState = (set: (updater: (state: baseState) => Partial<baseState>) => void) => ({
    openChat: false,
    page: 1,
    isEnd: false,
    switchChat: (input?: boolean) => {
        set(state => ({
            openChat: input ?? !state.openChat
        }));
    },
    currentChat: {} as ChatType,
    setCurrentChat: (currentChat: Partial<ChatType>) => {
        const newChatInfo = { ...currentChat, user_id: currentChat.user_id || currentChat.userId };
        set(() => ({
            currentChat: newChatInfo as ChatType
        }));
    },
    chatMap: {},
    setChatMapMessageList: (chatMessageList: ResMessage[], isInit: boolean) => {
        if (chatMessageList.length === 0) return;
        set(state => {
            if (isInit) {
                return {
                    chatMap: {
                        ...state.chatMap,
                        [chatMessageList[0].session_id]: {
                            messageList: chatMessageList.slice().reverse(),
                            chatInfo: state.chatMap[chatMessageList[0].session_id]?.chatInfo
                        }
                    }
                };
            } else {
                return {
                    chatMap: {
                        ...state.chatMap,
                        [chatMessageList[0].session_id]: {
                            messageList: [
                                ...chatMessageList.slice().reverse(),
                                ...state.chatMap[chatMessageList[0].session_id].messageList
                            ],
                            chatInfo: state.chatMap[chatMessageList[0].session_id]?.chatInfo
                        }
                    }
                };
            }
        });
    },
    setChatMapInfo: (chatInfo: ChatInfo) => {
        set(state => ({
            chatMap: {
                ...state.chatMap,
                [chatInfo.id]: {
                    messageList: state.chatMap[chatInfo.id]?.messageList || [],
                    chatInfo
                }
            }
        }));
    },
    setResMessage: (message: ResMessage) => {
        const update = async () => {
            const newList = useChatStore
                .getState()
                .chatList.slice()
                .sort((a, b) => {
                    if (a.id === message.session_id) return -1;
                    if (b.id === message.session_id) return 1;
                    return 0;
                });
            let newChat: ChatInfo | null = null;
            if (!newList.find(item => item.id === message.session_id)) {
                const userId = useUserStore.getState().userInfo.user_id;
                if (message.from !== userId) {
                    const res = await getChatInfo({
                        role: message.role,
                        to: message.from
                    });
                    if (res.status === 200) {
                        newChat = res.data;
                    }
                }
            }
            set(state => {
                const info = state.chatMap[message.session_id];
                const addList =
                    newList[0]?.id === message.session_id
                        ? newList
                        : newChat
                        ? [newChat, ...newList.filter(item => item.id !== message.session_id)]
                        : newList;

                const isOwner = useUserStore.getState().userInfo.user_id === message.from;
                const count = info
                    ? isOwner
                        ? info.chatInfo.unread_count
                        : info.chatInfo.unread_count + 1
                    : 1;

                const unreadNumber = isOwner ? 0 : 1;

                const unreadCount =
                    message.role === 'user'
                        ? state.chatUserUnread + unreadNumber
                        : state.chatCpUnread + unreadNumber;

                return {
                    chatMap: {
                        ...state.chatMap,
                        [message.session_id]: {
                            chatInfo: {
                                ...(info?.chatInfo ? info.chatInfo : newChat),
                                unread_count: count,
                                is_free: message.is_next_message_free,
                                ...(state.chatMap[message.session_id]?.chatInfo?.participants
                                    ? {
                                          participants: state.chatMap[
                                              message.session_id
                                          ].chatInfo.participants.map(item => {
                                              if (item.user_id === message.from) {
                                                  return {
                                                      ...item,
                                                      last_read_at: message.created_at
                                                  };
                                              }
                                              return item;
                                          })
                                      }
                                    : {})
                            } as ChatInfo,
                            messageList: [...(info?.messageList ? info.messageList : []), message]
                        }
                    },
                    chatList: addList,
                    [message.role === 'user' ? 'chatUserUnread' : 'chatCpUnread']: unreadCount
                };
            });
        };
        update();
    },
    chatList: [],
    setChatList: (chatList: ChatInfo[]) => {
        set(state => {
            const chatMap: ChatMap = {};
            for (const item of chatList) {
                chatMap[item.id] = {
                    messageList: [],
                    chatInfo: item
                };
            }
            const chatListMap = new Map(state.chatList.map(chat => [chat.id, chat]));
            chatList.forEach(chat => chatListMap.set(chat.id, chat));
            const uniqueChatList = Array.from(chatListMap.values());
            return {
                chatList: uniqueChatList,
                chatMap: { ...state.chatMap, ...chatMap }
            };
        });
    },
    setNewChatList: (chatInfo: ChatInfo) => {
        set(state => {
            const newList = [chatInfo, ...state.chatList.filter(item => item.id !== chatInfo.id)];
            return {
                chatList: newList
            };
        });
    },
    setChatMapRead: (sessionId: string) => {
        set(state => {
            return {
                chatMap: {
                    ...state.chatMap,
                    [sessionId]: {
                        messageList: state.chatMap[sessionId].messageList,
                        chatInfo: {
                            ...state.chatMap[sessionId].chatInfo,
                            unread_count: 0
                        }
                    }
                }
            };
        });
    },
    setChatMapMessageUnlock: (sessionId: string, info: UnlockMedia) => {
        set(state => {
            const newMessageList = state.chatMap[sessionId].messageList.map(item => {
                if (info.id !== item.id) return item;
                const newItem = {
                    ...item,
                    status: 3,
                    msg: {
                        ...item.msg,
                        content: info.msg.content,
                        cover: info.msg.detail.cover,
                        detail: info.msg.detail,
                        is_lock: false
                    }
                };
                return newItem;
            });

            return {
                chatMap: {
                    ...state.chatMap,
                    [sessionId]: {
                        messageList: newMessageList,
                        chatInfo: state.chatMap[sessionId].chatInfo
                    }
                }
            };
        });
    },
    setPage: () => {
        set(state => ({
            page: state.page + 1
        }));
    },
    setIsEnd: (end: boolean) => {
        set(() => ({
            isEnd: end
        }));
    },
    resetChatList: () => {
        set(() => ({
            chatList: [],
            page: 1,
            isEnd: false
        }));
    },
    chatUserUnread: 0,
    chatCpUnread: 0,
    setChatUserUnread: (num: number) => {
        set(state => ({
            chatUserUnread: state.chatUserUnread + num
        }));
    },
    setChatUserUnreadInit: (num: number) => {
        set(() => ({
            chatUserUnread: num
        }));
    },
    setChatCpUnread: (num: number) => {
        set(state => ({
            chatCpUnread: state.chatCpUnread + num
        }));
    },
    setChatCpUnreadInit: (num: number) => {
        set(() => ({
            chatCpUnread: num
        }));
    },
    updateReadTime: ({
        read_at,
        session_id,
        user_id
    }: {
        read_at: string;
        session_id: string;
        user_id: string;
    }) => {
        if (!useChatStore.getState().chatMap[session_id]) return;
        set(state => ({
            chatMap: {
                ...state.chatMap,
                [session_id]: {
                    ...state.chatMap[session_id],
                    chatInfo: {
                        ...state.chatMap[session_id].chatInfo,
                        participants: state.chatMap[session_id].chatInfo.participants.map(item => {
                            if (item.user_id === user_id) {
                                return { ...item, last_read_at: read_at };
                            }
                            return item;
                        })
                    }
                }
            }
        }));
    },
    replyData: {
        enable: false,
        img: '',
        description: '',
        video_id: '',
        image_id: '',
        drama_id: '',
        episode_id: '',
        trailer_id: ''
    },
    setReplyData: ({
        img,
        description,
        type,
        id
    }: {
        img: string;
        description: string;
        type: string;
        id: string;
    }) => {
        set(() => ({
            replyData: {
                enable: true,
                img,
                description,
                video_id: type === 'video' ? id : '',
                image_id: type === 'image' ? id : '',
                drama_id: type === 'drama' ? id : '',
                episode_id: type === 'episode' ? id : '',
                trailer_id: type === 'trailer' ? id : ''
            }
        }));
    },
    resetReplyData: () => {
        set(() => ({
            replyData: {
                enable: false,
                img: '',
                description: '',
                video_id: '',
                image_id: '',
                drama_id: '',
                episode_id: '',
                trailer_id: ''
            }
        }));
    },
    isOpenSticker: false,
    setIsOpenSticker: (status: boolean) => {
        set(() => ({
            isOpenSticker: status
        }));
    }
});

const createChatStore = () => {
    useChatStore = initStore<baseState>(initialState);
};

export { createChatStore, useChatStore };
